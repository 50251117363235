h3 {
    font-family: 'Helvetica';
}

#blinkOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d2580b;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease-in-out;
}

.section-header {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.section-header-subtitle {
    font-family: 'Arial';
}



.view-btn:hover {
    background-color: #bfbfbf;
}

.section-header-text {
    display: flex;
    flex-direction: column;
}

.admin-dashboard {
    width: 100%;
}

#editPageForm {
    font-family: 'Helvetica';
}

.dashboard-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    align-items: center;
}

.connect-btn {
    cursor: pointer;
}


.dashboard-btn {
    background: #1E293B;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    font-size: 14px;
}

.dropdown-toggle-btn {
    background: #1E293B;
    color: white;
    padding: 8px 10px;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 6px 6px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dashboard-btn:hover,
.dropdown-toggle-btn:hover {
    background: #334155;
}

.chevron-icon {
    width: 16px;
    height: 16px;
    transition: transform 0.2s;
}

.dropdown-content {
    font-family: 'Helvetica';
    display: none;
    right: 0;
    margin-top: 4px;
    background-color: white;
    min-width: 160px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    z-index: 1;
}

.dropdown-content.show {
    display: block;
    font-size: 14px;
}

.dropdown-item-dashboard {
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown-item {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown-item:hover {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    color: white;
    background: #d2580b;
    border-radius: 6px;
}

.dashboard-header-text {
    font-family: 'Helvetica';
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: black;
}

.dashboard-header-text-container {
    font-family: 'Helvetica';
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
}

.dashboard-header-text-subnav {
    font-family: 'Helvetica';
    font-size: 18px;
}

.connect-wallet {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.bns-name {
    font-family: 'Helvetica';
    color: #333;
    font-size: 24px;
    user-select: text;
    cursor: text;
    padding-top: 48px;
    padding-bottom: 12px;
}

.non-playable {
    cursor: default;
}

.bns-name-address {
    font-family: 'Helvetica';
    padding: 20px;
    color: rgb(154, 152, 152);
    font-size: 18px;
}

/* Overlay */
#overlay {
    display: none;
    /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 1;
    /* Ensures it is above other content */
}

.hidden {
    display: none !important;
}




.main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100%;
    margin: 20px auto;
    cursor: default;
    user-select: none;
}

.home-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.create-btn {
    background-color: #d2580b;
}

.create-btn:hover {
    background-color: #6f2c03;
}

.create-page-buttons {
    display: flex;
    justify-content: space-between;
}

.create-page-title {
    font-family: 'Helvetica';
}

.create-page-description {
    font-family: 'Helvetica';
}

.page-description {
    color: rgb(92, 90, 90);
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 18px;
    line-height: 1.625;
    user-select: text;
    cursor: text;
    max-width: 800px;
    margin: 0 auto 64px;
    padding: 0 24px;
}

.dashboard-page-description {
    color: rgb(92, 90, 90);
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
    line-height: 2;
}

.album-total-price {
    font-size: 16px;
    color: #666;
    display: flex;
    align-items: center;
    gap: 10px;
}

.album-quantity {
    font-size: 16px;
    color: #666;
    display: flex;
    align-items: center;
    gap: 10px;
}

.artwork-credit {
    font-size: 16px;
    color: #666;
    display: flex;
    align-items: center;
    gap: 10px;
}

.album-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.text-reveal-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100%;
    margin: 20px auto;
    cursor: default;
    user-select: none;
}

.text-unlock-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    height: 100%;

}

.unlock-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.unlock-left {
    display: flex;
    align-items: end;
    height: 250px;
    border-radius: 10px;
}

.text-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    border-image: url("/logos/welsh.png") 27 space;
}



.unlocked-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

.blurred {
    filter: blur(5px);
}

.clear {
    clip-path: circle(0px at -10% -10%);
    transition: clip-path 0.1s ease;
}


@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.profile-photo img {
    position: relative;
    border-radius: 50%;
    object-fit: cover;
    z-index: 2;
}

.risememe-photo {
    background: transparent !important;
    background-color: black !important;
    background-image: none !important;
    display: block;
    overflow: visible;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


select {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.popupContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


button {
    cursor: pointer;
    min-width: fit-content;
}


.button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.gate-icon {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}


.locked-content {
    color: red;
    /* Changes text color to red */
    background-color: #ffcccc;
    /* Light red background for more emphasis */
    border: 1px solid red;
    /* Optional: adds a red border */
    padding: 10px;
    /* Adds some spacing around the text */
    margin: 10px 0;
    /* Extra spacing for aesthetics */
    transition: all 0.3s ease;
    /* Smooth transition for any changes */
}

@keyframes blink {

    0%,
    100% {
        background-color: transparent;
    }

    50% {
        background-color: green;
    }
}

.blink-effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0.5;
    display: none;
    animation: blink 1s linear;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    position: absolute;
    width: 100%;
}

.gate-container {
    display: flex;
    flex-direction: column;
}

.gate-icon2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: none;
}


.gate-bar2 {
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: #333;
    transform: rotate(-15deg)
}

.gate-bar2:nth-child(1) {
    left: 25%;
}

.gate-bar2:nth-child(2) {
    left: 50%;
}

.gate-bar2:nth-child(3) {
    left: 75%;
}

.empty-state {
    color: #ffffff;
    padding: 0.2px;
    padding-left: 5px;
    border-radius: 4px;
    height: min-content;
    font-size: 14px;
    background: #d2580b;
}

.sidebar {
    transition: right 0.3s ease-in-out;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-right: #e8e8e8 solid 2px;
    width: 20%;
    min-width: fit-content;
}

.sidebar-header {
    font-family: 'Helvetica';
    font-size: 1.125rem;
    color: black;
    font-weight: 600;
    margin-top: 20px;
}

.sidebar-list {
    padding: 20px;
    font-family: 'Helvetica';
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    transition: all 0.2s;
    cursor: pointer;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    transition: all 0.2s;
    cursor: pointer;
}


.btn-secondary {
    background-color: transparent;
    border: 1px solid hsl(var(--border));
    color: hsl(var(--muted-foreground));
}

.btn-secondary:hover {
    background-color: hsl(var(--muted) / 0.1);
}

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.toggle-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.titlepluslock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.lockable-section-1 {
    background-color: rgba(202, 229, 172, 0.231);
    border: rgb(203, 215, 215) 1px solid;
    border-radius: 5px;
    padding: 10px;
}

.lockable-section-2 {
    background-color: rgba(103, 201, 103, 0.174);
    border: cadetblue 1px solid;
    border-radius: 5px;
    padding: 10px;
}

.form-container {
    font-family: 'Helvetica';
    max-width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.form-section {
    margin-bottom: 20px;
}

.form-section h3 {
    margin-bottom: 10px;
    color: #333;
    font-size: 18px;
}

input[type="text"],
textarea,
select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    /* Include padding and border in element's width */
}


textarea {
    height: 100px;
    /* Set textarea height */
}

.form-actions {
    text-align: right;
}

.form-actions button {
    padding: 10px 20px;
    margin-left: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-actions button:hover {
    background-color: #0056b3;
}

.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px 25px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.floating-button:hover {
    background-color: #333333;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.floating-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
    font-family: Arial, sans-serif;
}

h2 {
    font-family: Arial, sans-serif;
}

p {
    font-family: Arial, sans-serif;
}

#store-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100%;
    cursor: default;
    user-select: none;
}

.sellable-item {
    border-radius: 5px;
    font-family: Arial, sans-serif;
    background-color: #54729a2d;
    padding: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #7b9ec370;
}

.item-label {
    display: flex;
    align-items: center;
    gap: 10px;
    filter: blur(4px);
}

.tag-icon {
    margin-right: 10px;
}


button {
    background-color: #2b2a31;
    color: white;
    border: none;
    padding: 7px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 100%;
}

button:hover {
    background-color: #6f6e6e;
    cursor: pointer;
}

.cart-icon {
    margin-right: 5px;
}

.token-gated-content {
    border-radius: 5px;
    font-family: Arial, sans-serif;
    background-color: #54729a2d;
    padding: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #7b9ec370;
}

.blurred-content {
    filter: blur(5px);
}

.content-item {
    background-color: rgb(255, 250, 250);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;
    padding: 20px;
}

body {
    background-color: #f6f3f0;
    overflow-x: hidden;
}

.dark.dark-theme {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.conditions-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #464646;
    cursor: pointer;
    position: relative;
    font-family: 'Helvetica';
}

.info-icon {
    width: 16px;
    height: 16px;
    color: #4a4a4a;
}

.conditions-popup {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 8px;
    width: 250px;
}

.conditions-popup h4 {
    margin: 0 0 12px;
    font-size: 16px;
    color: #333;
}

.conditions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.conditions-list li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.token-icon,
.balance-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.token-icon {
    border-radius: 50%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.303);
    z-index: 1;
    font-family: 'Helvetica';
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    padding: 20px 24px;
    border-bottom: 1px solid #eee;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.modal-body {
    padding: 24px;
    max-height: calc(90vh - 120px);
    /* Adjust for header and footer */
    overflow-y: auto;
}

.modal-subtitle {
    color: #666;
    margin: 0 0 20px 0;
}

.modal-footer {
    position: sticky;
    /* Make footer sticky */
    bottom: 0;
    background: white;
    padding: 16px 24px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.blocks-preview {
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
}

.block-preview-item {
    display: flex;
    flex-direction: column;
}

.info-grid {
    display: grid;
    gap: 16px;
    padding-right: 10px;
    /* Prevents content from touching scrollbar */
}

.info-item {
    display: grid;
    gap: 4px;
}

.info-label {
    font-family: 'Helvetica';
    font-size: 0.875rem;
    color: #666;
    font-weight: 500;
}

.info-value {
    font-family: 'Helvetica';
    font-size: 0.9375rem;
    color: #111;
}

.url-text {
    font-family: monospace;
    font-size: 0.875rem;
    color: #444;
    word-break: break-all;
}

.status-badge {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 500;
}

.status-badge.status-active {
    background: #e6f4ea;
    color: #1e7e34;
}

.status-badge.status-inactive {
    background: #feeee6;
    color: #dc4c24;
}



.multi-nft-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

.nft-card {
    font-family: 'Helvetica';
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
}

.nft-artist {
    margin-bottom: 40px;
}

.nft-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.nft-image {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}

.nft-header {
    display: flex;
    align-items: center;
}

.nft-icon {
    font-size: 24px;
    margin-right: 10px;
}

.nft-name {
    font-size: 18px;
    font-weight: bold;
}

.nft-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.nft-mint-status {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 15px;
    white-space: nowrap;
}


.nft-benefits-toggle {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    background-color: #F3F4F6;
}

.nft-benefits-toggle:hover {
    background-color: #F3F4F6;
}

.nft-mint-button {
    background-color: #2b2a31;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.mint-button {
    background-color: #2b2a31;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 10px;
}

.nft-benefits-list {
    background-color: #9dbbf8;
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 10px;
}

.benefits-list {
    display: flex;
    padding: 20px;
    border-radius: 5px;
    background-color: #F3F4F6;
    gap: 30px;
    color: #666;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.nft-benefits-list.hidden {
    display: none;
}

.nft-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

/* Music Player Card Styles */
.music-player-card {
    background-color: #181818f7;
    color: #ffffff;
    padding: 20px;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.container-title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    padding: 20px;
    color: #333;
}

.container-song {
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.container-description {
    font-family: Arial, Helvetica, sans-serif;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
    padding: 20px;
}

.music-subtitle {
    font-size: 16px;
    color: #666
}

.card-header {
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    filter: blur(7px)
}

.card-header-container {
    display: flex;

    flex-direction: column;

}

#album-art {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    filter: blur(7px);
}

.album-cover {
    position: relative;
    overflow: hidden;
}

.album-cover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to bottom,
            rgba(18, 18, 18, 0.7) 0%,
            rgba(18, 18, 18, 0.3) 50%,
            rgba(18, 18, 18, 0) 100%);
    pointer-events: none;
}

.card-header h2 {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Artist Info Styles */
.artist-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}



.two-column-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.buyButtonContainer {
    display: flex;
    flex-direction: column;
}

.artist-info img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 15px;
}

.artist-details {
    flex-grow: 1;
}

.artist-details h3 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
}

.artist-details p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #b3b3b3;
}

.album-info-sticky {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Music Player Styles */
.music-player {
    display: flex;
    flex-direction: row;
    background-color: #282828da;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.buy-album-button {
    display: block;
    border: solid 1px #d2580b;
    max-height: 40px;
    padding: 5px;
}

.buy-album-button:hover {
    background-color: #d2580b;
    color: black;
}

.buy-album-button:focus {
    background-color: #09160a;
    border: none;
    box-shadow: inset;
    color: white;
}

.bns-name a {
    text-decoration: none;
    font-family: 'Helvetica';
    padding: 20px;
    color: rgb(154, 152, 152);
    font-size: 24px;
}

.bns-name:hover a {
    text-decoration: none;
    font-family: 'Helvetica';
    padding: 20px;
    color: rgb(42, 42, 42);
    font-size: 24px;
}

.album-buy-container {
    display: flex;
    justify-content: center;
}

.album-image {
    width: 250px;
    object-fit: cover;
    border-radius: 4px;
    filter: blur(6px);
}

.album-controls {
    display: flex;
    flex-direction: row;
}

.album-image-container {
    display: flex;
    justify-content: center;
    padding: 40px;
}

#music-player-sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #222222;
    color: #fff;
    display: none;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1000;
}


#music-player-sticky img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

#music-player-sticky #track-info {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    font-family: Arial, Helvetica, sans-serif;
    justify-content: center;
}

#music-player-sticky button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    margin: 0 5px;
    cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    /* Thumb height */
    width: 20px;
    /* Thumb width */
    background: #d2580b;
    /* Desired thumb color */
    border-radius: 50%;
    /* Makes the thumb circular */
    cursor: pointer;
    /* Cursor on hover */
    margin-top: -7.5px;
    /* Centers the thumb on the track */
}



#music-player-sticky input[type="range"]#progress-bar {
    -webkit-appearance: none;
    /* Chrome, Safari, Edge */
    -moz-appearance: none;
    /* Firefox */
    appearance: none;
    /* Standard */
    background: transparent;
    /* Remove default background */
    flex-grow: 1;
}

#music-player-sticky input[type="range"]#volume-control {
    -webkit-appearance: none;
    /* Chrome, Safari, Edge */
    -moz-appearance: none;
    /* Firefox */
    appearance: none;
    /* Standard */
    background: transparent;
    /* Remove default background */
    width: 100px;
}

#music-player-sticky input[type="range"]::-webkit-slider-thumb {
    background: #d2580b;
    width: 40px;
    height: 15px;
    margin-top: -5px;
    border: 1px solid #d2580b;
    border-radius: 5px;
    cursor: pointer;
}

#music-player-sticky input[type="range"]::-webkit-slider-runnable-track {
    background: #444;
    height: 5px;
    border-radius: 5px;
    box-shadow: inset 2px 0px 2px;
}

#audio-player {
    display: none;
}

.album-nav-sticky {
    display: flex;
    flex-direction: row;
}

.album-title {
    font-size: 28px;
}

.char-count-title {
    font-family: 'Helvetica';
    font-size: 14px;
    color: grey;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.page-card {
    display: flex;
    flex-direction: column !important;
    padding: 20px;
    justify-content: center;
    background: white;
    border-radius: 10px;
    margin-bottom: 40px;
    backdrop-filter: blur(1px);
    box-shadow: 0px 2px 5px 1px lightgray;
    width: auto;
}

@media (max-width: 768px) {


    #music-player-sticky input[type="range"]#progress-bar {
        display: none;
    }

    .unlock-container {
        flex-direction: column;
    }


    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        background-color: rgb(249, 249, 249);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 90%;
        max-width: 600px;
    }



    .conditions-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 300px;
    }
}

/* Responsive adjustments */
@media (max-width: 600px) {

    .view-button {
        display: flex;
        color: black;
        font-weight: 600;
        border: 1px solid lightgray;
        border-radius: 0.5rem;
        padding: 10px;
        box-shadow: 0px 0px 6px 0px lightgray;
        font-size: 14px;
        text-decoration: none;
        text-align: center;
        align-items: center;
    }

    .page-actions {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-direction: column;
    }

    .page-card {
        display: flex;
        flex-direction: column !important;
        padding: 20px;
        justify-content: center;
        background: white;
        border-radius: 10px;
        margin-bottom: 40px;
        backdrop-filter: blur(1px);
        box-shadow: 0px 2px 5px 1px lightgray;
        width: auto;
    }

    .section-header {
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        gap: 20px;
    }


    #volume-control {
        display: none;
    }

    #music-player-sticky {
        display: flex;
        justify-content: space-between;
        display: none;
    }

    #music-player-sticky img {
        margin-bottom: 10px;
    }

    #music-player-sticky button {
        font-size: 20px;
    }

    #music-player-sticky input[type="range"]#volume-control {
        width: 100%;
    }


}

.current-song {
    display: flex;
    flex-direction: column;
}

.current-track-section {
    display: flex;
    flex-direction: row;
    background-color: #282828da;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.current-track-info {
    line-height: 25px;
}

.album-price {
    color: #666;
}

.album-supply {
    color: #666
}

.album-artwork {
    color: #666
}

.image-artist {
    color: #666;
}

.song-title:hover {
    color: #d2580b;
    /* Remove default margins for tighter layout */
    cursor: pointer;
}

.song-artist {
    font-size: 16px;
    color: #666;
    display: flex;
    align-items: center;
    gap: 10px;
}

.song-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 4px;
    filter: blur(6px);
    cursor: pointer;
}

.image-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
}

.image-modal img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}


.loader {
    color: #ffffff;
    font-size: 20px;
}

#song-duration {
    font-family: Arial, Helvetica, sans-serif;
}

.song-duration {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    text-align: right;
    padding-left: 20px;
    color: #666;
}

.song-details {
    display: flex;
    align-items: center;
}

.song-number {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #666;
}

.song-number .song-image {
    width: 30px;
    /* Adjust as needed */
    height: 30px;
    /* Adjust as needed */
    margin-left: 10px;
    /* Space between number and image */
    object-fit: cover;
    filter: blur(3px);
    /* Adds blur effect */
    transition: filter 0.3s ease;
    /* Smooth transition for hover effect */
}

.song-details-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;

}

.player-controls {
    display: flex;
    gap: 20px;
    color: white;
}

.player-controls button svg {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
}

/* Set the default fill color to white */
.control-button svg path {
    fill: #ffffff;
    /* Targeting the path element directly */
}

/* Change the fill color to orange on hover */
.control-button:hover svg path {
    fill: #d2580b;
}

.musiccontrol {
    border: none;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 5px;
}

.player-controls button:hover {
    color: #1db954;
}

/* Song List Styles */
.song-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.song-list li {
    display: flex;
    gap: "5px";
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #282828;
}

.song-details {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.song-list li:hover {
    display: flex;
    align-items: center;
    padding: 10px 0;
    background-color: #282828;
    border-radius: 5px;
    padding: 10px;
}

.song-list li:last-child {
    border-bottom: none;
}

.song-list li span:first-child {
    font-size: 16px;
}

.song-list li span:last-child {
    font-size: 14px;
    color: #b3b3b3;
}

.song-action {
    display: flex;
    align-items: center;
}

.song-row {
    display: flex;
    justify-content: space-between;
    /* Pushes duration to the right */
    align-items: center;
    /* Vertically centers items in the row */
    width: 100%;
}


.mint-track-btn {
    display: none;
    position: absolute;
    right: 10px;
    color: black;
    border: none;
    cursor: pointer;
    max-height: 30px;
    padding: 5px;
    font-size: 12px;
}

.container-song:hover .mint-track-btn {
    display: block;
    background-color: #d2580b;
}

.container-song:hover .mint-track-btn:focus {
    display: block;
    background-color: #09160a;
}



.follow-button {
    right: 10px;
    top: 50%;

    color: #ffa500;
    border: 1px #ffa500 solid;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
}


.profile-outer-container {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: visible;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    will-change: transform;
}

.profile-inner-container {
    width: 180px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.profile-photo {
    height: 180px;
    width: 180px;
    max-height: 180px;
    background: rgb(39, 39, 39);
    background-size: cover;
    background-position: center;
    transition: filter 0.3s ease;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-photo.tipped {
    filter: hue-rotate(90deg) brightness(1.2);
}

.tip-text {
    font-family: 'Helvetica';
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    white-space: nowrap;
    z-index: 10;
}

.tip-text.show {
    opacity: 1;
    visibility: visible;
}

.tip-text:hover {
    background-color: rgba(255, 255, 255, 1);
}

.wave {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 70%);
    transform: translate(-50%, -50%) scale(0);
    z-index: 5;
}

@keyframes ripple {
    to {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
    }
}

.floating-emoji {
    position: absolute;
    font-size: 24px;
    user-select: none;
    pointer-events: none;
    will-change: transform, opacity;
    z-index: 15;
    animation: floatUp 2s ease-out forwards;
}

@keyframes floatUp {
    0% {
        transform: translateY(0) scale(0.5);
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        transform: translateY(-100px) scale(1.5) rotate(20deg);
        opacity: 0;
    }
}



.pages-section {
    display: flex;
    flex-direction: column;
    width: 80%;
}

#pages-list {
    padding: 20px;
    font-family: 'Helvetica';
}

.page-list-header {
    padding: 16px 24px;
    border-bottom: 1px solid hsl(var(--border));
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.page-card-content {
    display: flex;
    flex-direction: column;
    min-width: 80%;

}

.page-card-content-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-card-description {
    color: gray;
    max-height: fit-content;
    min-height: fit-content;
}

.page-item {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid hsl(var(--border));
}

.page-info {
    flex: 1;
}

.page-title {
    font-size: 16px;
    font-weight: 500;
    color: hsl(var(--foreground));
    margin-bottom: 4px;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status {
    font-size: 12px;
    padding: 4px 8px;
    margin: 8px 0;
    display: inline-block;
    background-color: rgb(220 252 231);
    color: #1db954;
    border-radius: 0.5rem;
}

.status-btn.active {

    font-size: 14px;
    padding: 10px 12px;
    margin: 17px 0;
    display: inline-block;
    background-color: #ef4444;
    color: white;
    font-weight: 500;
    border-radius: 0.5rem;
}

.status-draft {
    background-color: hsl(var(--warning) / 0.2);
    color: hsl(var(--warning));
}

.status-published {
    background-color: hsl(var(--success) / 0.2);
    color: hsl(var(--success));
}

.page-actions {
    display: flex;
    gap: 8px;
    align-items: center;
}

.logo {
    width: 80px;
    cursor: auto;
    cursor: pointer;
}

.dashboard-actions,
.action-buttons {
    display: flex;
    justify-content: space-between;
}

.dashboard-content {
    display: flex;
    flex-direction: row;
    border: solid 2px #e8e8e8;
    border-radius: 8px;
}


.button-group {
    display: flex;
    justify-content: space-between;
}

.wallet-info {
    margin-bottom: 2rem;
    color: #6b7280;
}

.page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2rem;
}

.ownership-section {
    margin-top: auto;
    text-align: center;
}

.verify-button {
    background: #2d2d2d;
    color: white;
    border: none;
    padding: 12px 18px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    transition: background 0.2s ease;
}

.verify-button:hover {
    background: #404040;
}

.verify-icon {
    color: #4caf50;
}

.profile-top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-card .settings-panel {
    display: flex !important;
    flex-direction: column;
    margin-top: 20px;
    border-top: 1px solid #E0E0E0;
    padding-top: 20px;
}

.page-card .settings-panel.none {
    display: none !important;
}



.page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.page-title h3 {
    margin: 0 0 8px 0;
    font-size: 18px;
}

.status-badge {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.status-badge.active {
    background: #E8F5E9;
    color: #2E7D32;
}

.status-badge.inactive {
    background: #FFEBEE;
    color: #C62828;
}

.page-actions {
    display: flex;
    gap: 8px;
}

.view-btn {
    padding: 6px 16px;
    border-radius: 6px;
    background: #F5F5F5;
    color: #333;
    text-decoration: none;
    font-size: 14px;
    min-width: 75px;
}

.settings-cog {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #666;
    border-radius: 4px;
    font-size: 16px;
}

.settings-cog:hover {
    background: #F5F5F5;
}



.settings-grid {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-bottom: 24px;
}

.settings-section h4 {
    margin: 0 0 16px 0;
    font-size: 16px;
    color: #333;
}

.settings-group {
    margin-bottom: 16px;
}

.settings-group label {
    display: flex;
    margin-bottom: 8px;
    font-size: 14px;
    color: #666;
}

.settings-group input[type="text"],
.settings-group input[type="number"],
.settings-group select,
.settings-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    font-size: 14px;
}

.settings-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid #E0E0E0;
}


.action-buttons {
    display: flex;
    gap: 12px;
}

.delete-btn {
    padding: 8px 16px;
    border: none;
    background: #D32F2F;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
}

.delete-btn:hover {
    padding: 8px 16px;
    border: none;
    background: #721a1a;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
}

.cancel-btn {
    background-color: transparent;
    border: 1px solid #E0E0E0;
    color: hsl(var(--muted-foreground));
}

.cancel-btn:hover {
    background-color: #0000001a;
}

.cancel-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.save-btn {
    border: none;
    background: #2b2a31;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    gap: 5px;
}

.save-btn:hover {
    border: none;
    background: #2b2a31;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    gap: 5px;
}

.toggle-switch {
    display: inline-block;
}

.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch label {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    background-color: #E0E0E0;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-switch label::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    top: 2px;
    left: 2px;
    transition: left 0.3s;
}

.toggle-switch input[type="checkbox"]:checked+label {
    background-color: #2196F3;
}

.toggle-switch input[type="checkbox"]:checked+label::after {
    left: 28px;
}

.notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #4CAF50;
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    z-index: 1000;
    animation: slideIn 0.3s ease-out;
}

.section-header-title {
    font-size: 26px;
    font-family: "Helvetica";
    font-weight: 700;
    margin-bottom: 10px;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.nft-dropdown-container {
    margin-top: 10px;
}

.nft-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}

.nft-select option {
    padding: 8px;
}

.error {
    color: red;
    margin-top: 5px;
    font-size: 14px;
}

.nft-preview {
    display: grid;
    width: 100%;
}

.nft-preview-modal {
    display: flex;
    align-items: center;
    padding: 5px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.under-logo {
    background-color: #d8d8d8;
    font-size: 12px;
    border: orange;
    color: #1e1e1e;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5px;
}

.social-links {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin: 16px 0;
}

.social-link {
    font-size: x-large;
    color: #666;
    transition: color 0.2s;
    cursor: pointer;
}

.social-link:hover {
    color: #000;
}

.social-links-fields {
    margin-top: 12px;
}

.social-links-fields.hidden {
    display: none;
}

.input-group+.input-group {
    margin-top: 12px;
}

#socialLinksFields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    color: gray;
    font-size: 14px;
}

.social-fields {
    transition: all 0.3s ease;
}

.edit-sidebar {
    position: fixed;
    top: 0;
    left: -350px;
    width: 350px;
    height: 100vh;
    background: white;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
}

.edit-sidebar.visible+.page-content {
    width: calc(100% - 350px);
    margin-left: 350px;
}

.edit-sidebar-content {
    padding: 20px;
    /* Add padding to account for close button */
    overflow-y: auto;
    flex-grow: 1;
    height: calc(100vh - 100px);
    /* Account for padding and button */
}

.close-btn-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.close-btn {
    position: relative;
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
}

.social-links-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 32px;
}

#main-content {
    display: flex;
    flex-direction: column;
}


.nft-collection-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 24px;
    width: 100%;
    margin-bottom: 24px;
}



.nft-preview:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.preview-profile-photo {
    border: solid;
    border-radius: 360px;
    height: 125px;
}



.nft-details {
    /* Change from absolute */
    top: 20px;
    left: 20px;
    color: white;
    z-index: 1001;
}

.nft-preview.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: none;
    transition: none;
    cursor: default;
}

/* Remove any transforms/transitions on fullscreen */
.nft-preview.fullscreen img {
    transform: none;
    transition: none;
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain;
}

.nft-details-content {
    font-family: 'Helvetica';
    line-height: 1.6125;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nft-collection-grid-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.page-content {
    min-height: 100px;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
}

.content-editable-area {
    min-height: 200px;
    position: relative;
}

.content-drop-zone {
    min-height: 100px;
    padding: 20px;
    position: relative;
    transition: opacity 0.3s;
}

.content-drop-zone.hidden {
    display: none;
}

.drop-indicator {
    font-family: 'Helvetica';
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    color: #666;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.drag-active .drop-indicator {
    border-color: #4CAF50;
    background: rgba(76, 175, 80, 0.1);
}

.editable-block {
    display: flex;
    position: relative;
    margin: 10px 0;
    border-radius: 4px;
    cursor: default;
}

.editable-block:hover {
    background: rgba(0, 0, 0, 0.02);
}

.editable-block.dragging {
    opacity: 0.5;
    cursor: move;
}

.editable-block.dragging-started {
    position: relative;
    z-index: 1000;
}

.editable-block.dragging-started~.editable-block.drop-before::before,
.editable-block.dragging-started~.editable-block.drop-after::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    background: #2196F3;
    box-shadow: 0 0 4px rgba(33, 150, 243, 0.5);
}

.editable-block.drop-before::before,
.editable-block.drop-after::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    background: #2196F3;
    box-shadow: 0 0 4px rgba(33, 150, 243, 0.5);
}

.editable-block.drop-before::before {
    top: -2px;
}

.editable-block.drop-after::after {
    bottom: -2px;
}

.block-controls {
    display: none;
    transition: opacity 0.3s;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.editable-block:hover .block-controls {
    display: flex;
    opacity: 1;
}

.block-controls button {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
}

.block-controls button:hover {
    background: #f5f5f5;
}

.editable {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.625;
    user-select: text;
    cursor: text;
    width: 100%;
    margin: 0 24px;
}

p.editable {
    color: #7d7d7d;
    max-width: 800px;
    line-height: 1.6125;
    white-space: pre-line;
}

p.editable[placeholder]:empty:before {
    content: attr(placeholder);
    color: rgba(125, 125, 125, 0.6);
    color: #7d7d7d;
    max-width: 800px;
    margin: 24px 24px;
    padding: 0 24px;
    line-height: 1.6125;
}


.editable:focus {
    background: rgba(238, 151, 10, 0.035);
}

.editable:empty:before {
    content: attr(placeholder);
    color: #999;
}

.moving {
    opacity: 0.5;
}

.content-container {
    min-height: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 16px 0;
    padding: 16px;
}

.content-controls {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.add-block-btn {
    background: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
}

.add-block-btn:hover {
    background: #e0e0e0;
}

.image-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.image-preview img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.move-block {
    cursor: move;
    padding: 4px 8px;
    background: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #333;
}

.move-block:hover {
    color: #2196F3;
}