/* Main container using shadcn patterns */

/* Content card */
.claim-content {
    width: 100%;
    max-width: 400px;
    background-color: hsl(var(--background));
    border: 1px solid hsl(var(--border));
    border-radius: var(--radius-lg);
    padding: var(--space-8);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

/* Logo container */
.claim-logo {
    width: 120px;
    height: auto;
    margin-inline: auto;
    margin-bottom: var(--space-8);
}

/* Heading styles */
.claim-content h1 {
    color: hsl(var(--foreground));
    font-size: var(--text-2xl);
    font-weight: 600;
    letter-spacing: -0.025em;
    text-align: center;
    margin-bottom: var(--space-6);
}

/* Connect button styling */
.connect-button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-md);
    font-size: var(--text-sm);
    font-weight: 500;
    height: 40px;
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    background-color: hsl(var(--primary));
    color: hsl(var(--primary-foreground));
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.connect-button:hover {
    background-color: hsl(var(--primary) / 0.9);
}

.connect-button:active {
    background-color: hsl(var(--primary) / 0.8);
}

/* Loading state */
.connect-button.loading {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Testnet banner */
.testnet-banner {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: hsl(var(--warning) / 0.2);
    border-bottom: 1px solid hsl(var(--warning) / 0.2);
    padding: var(--space-2) var(--space-4);
    text-align: center;
    color: hsl(var(--warning));
    font-size: var(--text-sm);
    z-index: 50;
}

/* Description text if needed */
.claim-description {
    color: hsl(var(--muted-foreground));
    text-align: center;
    margin-bottom: var(--space-6);
    font-size: var(--text-sm);
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .claim-container {
        padding: var(--space-4);
    }

    .claim-content {
        padding: var(--space-6);
    }

    .claim-logo {
        width: 100px;
        margin-bottom: var(--space-6);
    }

    .claim-content h1 {
        font-size: var(--text-xl);
    }
}